import axios from 'axios'
import Vue from 'vue'

const endUserTransactions = {
  state: () => ({
    loading: false,
    transactions: [],
    pagination: {
      pageSize: 10,
      totalItems: 0,
      nextPageToken: '',
      previousPageToken: '',
      currentPageToken: ''
    },
    dailyCosts: [],
    dailyCostsTotal: 0,
    topUps: [],
    balance: 0, 
    error: null
  }),

  mutations: {
    SET_END_USERS_TX_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_END_USERS_TX_ERROR: (state, error) => {
      state.error = error
    },
    SET_END_USERS_TX_PAGINATION_PAGE_SIZE: (state, pageSize) => {
      state.pagination.pageSize = pageSize
      // Also resetting the pagination token
      // as it's not valid for certain situations
      state.pagination.nextPageToken = ''
      state.pagination.previousPageToken = ''
    },
    SET_END_USERS_TX_PAGINATION_CURRENT_PAGE_TOKEN: (state, currentPageToken) => {
      state.pagination.currentPageToken = currentPageToken
    },
    SET_END_USERS_TX_PAGINATION: (state, headers) => {
      if ('previous-page-token' in headers) {
        state.pagination.previousPageToken = headers['previous-page-token']
      } else {
        state.pagination.previousPageToken = ''
      }

      if ('next-page-token' in headers) {
        state.pagination.nextPageToken = headers['next-page-token']
      } else {
        state.pagination.nextPageToken = ''
      }
      state.pagination.totalItems = parseInt(headers['total-items'])
    },
    SET_END_USERS_TX: (state, transactions) => {
      state.transactions = transactions
    },
    SET_END_USERS_TOPUP_TX: (state, topUps) => {
      state.topUps = topUps
    },
    SET_END_USERS_DAILY_COSTS: (state, usage) => {
      state.dailyCosts = usage.dailyCosts
      state.dailyCostsTotal = usage.totalAmount
    },
    SET_BALANCE: (state, balance) => {
      state.balance = balance
    },    
  },

  actions: {
    SetEndUserTransactionsPageSize(context, pageSize) {
      Vue.ls.set('END_USERS_PAGE_SIZE', pageSize)
      context.commit('SET_END_USERS_TX_PAGINATION_PAGE_SIZE', pageSize)
    },
    LoadEndUserTransactionsPageSize(context) {
      let pageSize = Vue.ls.get('END_USERS_PAGE_SIZE');
      context.commit('SET_END_USERS_TX_PAGINATION_PAGE_SIZE', pageSize)
    },
    ListEndUserTransactions (context, query) {
      context.commit('SET_END_USERS_TX_LOADING', true)

      let path = `/projects/${query.projectId}/end-users-transactions?`

      if (query.pageSize) {
        path += `&pageSize=${query.pageSize}`
      }

      if (query.modelId && query.modelId !== '') {
        path += `&model=${query.modelId}`
      }

      if (query.user && query.user !== '') {
        path += `&user=${query.user}`
      }      

      if (query.pageToken !== '') {
        path = path + `&pageToken=${query.pageToken}`
      }

      // return axios.get(`/gens?project=${query.projectId}&user=${query.user}&status=${query.status}`)
      return axios.get(path)
        .then(async (response) => {
          context.commit("SET_END_USERS_TX_PAGINATION_CURRENT_PAGE_TOKEN", query.pageToken)
          context.commit("SET_END_USERS_TX_PAGINATION", response.headers)

          context.commit('SET_END_USERS_TX', response.data.results)
          context.commit('SET_END_USERS_TX_LOADING', false)
        })
        .catch((error) => {
          console.log(error)     
          context.commit('SET_END_USERS_TX_LOADING', false)     
          context.commit('SET_END_USERS_TX_ERROR', error)
        })
    },
    ListEndUserTopUps (context, query) {
      context.commit('SET_END_USERS_TX_LOADING', true)

      let path = `/projects/${query.projectId}/end-users-transactions?type=credit&pageSize=200&user=${query.user}`      

      // return axios.get(`/gens?project=${query.projectId}&user=${query.user}&status=${query.status}`)
      return axios.get(path)
        .then(async (response) => {          
          context.commit('SET_END_USERS_TOPUP_TX', response.data.results)
          context.commit('SET_END_USERS_TX_LOADING', false)
        })
        .catch((error) => {
          console.log(error)     
          context.commit('SET_END_USERS_TX_LOADING', false)     
          context.commit('SET_END_USERS_TX_ERROR', error)
        })
    },
    GetBalance (context, query) {
      context.commit('SET_END_USERS_TX_LOADING', true)

      let path = `/projects/${query.projectId}/end-users-credits?user=${query.user}`
      return axios.get(path)
        .then(async (response) => {
          context.commit('SET_BALANCE', response.data.balance)
          context.commit('SET_END_USERS_TX_LOADING', false)
        })
        .catch((error) => {
          console.log(error)     
          context.commit('SET_END_USERS_TX_LOADING', false)     
          context.commit('SET_END_USERS_TX_ERROR', error)
        })
    },
    UpdateBalance (context, query) {
      context.commit('SET_END_USERS_TX_LOADING', true)

      let path = `/projects/${query.projectId}/end-users-credits`

      const data = {
        user: query.user,
        amount: query.amount,
      }

      return axios.put(path, data)
        .then(async () => {        
          context.commit('SET_END_USERS_TX_LOADING', false)
        })
        .catch((error) => {
          console.log(error)     
          context.commit('SET_END_USERS_TX_LOADING', false)     
          context.commit('SET_END_USERS_TX_ERROR', error)
        })
    },
    ListEndUsersUsage (context, query) {
      context.commit('SET_END_USERS_TX_LOADING', true)

      let path = `/projects/${query.projectId}/usage?`

      if (query.modelId && query.modelId !== '') {
        path += `&model=${query.modelId}`
      }

      if (query.user && query.user !== '') {
        path += `&user=${query.user}`
      }      

      // return axios.get(`/gens?project=${query.projectId}&user=${query.user}&status=${query.status}`)
      return axios.get(path)
        .then(async (response) => {
          context.commit('SET_END_USERS_DAILY_COSTS', response.data)
          context.commit('SET_END_USERS_TX_LOADING', false)
        })
        .catch((error) => {
          console.log(error)     
          context.commit('SET_END_USERS_TX_LOADING', false)     
          context.commit('SET_END_USERS_TX_ERROR', error)
        })
    },      
  }
}

export default endUserTransactions
