<template>
  <v-app-bar app dark dense fixed color="#212121">
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      @click.native.stop="
        $store.commit('VUETIFY_DRAWER', !$store.state.user.drawer.isActive)
      "
    ></v-app-bar-nav-icon>
    <a href="/">
      <v-list-item-avatar class="mr-4">
        <img alt="meteron-logo" src="/logo.png"/>         
      </v-list-item-avatar>
    </a>
    <v-toolbar-title class="font-weight-bold" router href="/">Meteron</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-spacer></v-spacer>
  
    <v-menu
      class="items"
      bottom
      left
      offset-y
      origin="top right"
      transition="v-slide-y-transition"
    >
      <template v-slot:activator="{ on: on }">
        <v-btn icon light v-on="on" class="white--text">
          <v-avatar v-if="avatarUrl" size="32">
            <img
              :src="avatarUrl"
              :alt="$store.state.user.user.name"
            >
          </v-avatar>
          <v-icon v-else>settings</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <span v-for="(item, i) in dropdown_items" v-bind:key="i">
          <v-divider v-if="item.divider"></v-divider>
          <v-list-item
            v-else
            :key="i"
            :to="!item.target ? item.href : null"
            :href="item.target ? item.href : null"
            v-bind:router="!item.target"
            target="item.target"
          >
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </span>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<style>

</style>

<script>

export default {
  components: {
  },
  data() {
    return {
      tabs: null,
      context: 'app context',
      loaded: false,
      docs_href: 'https://meteron.ai/',
      // how_to_items: [
      //   { text: 'Managing servers', href: '#', target: '_blank' },
      //   { text: 'Deploying applications', href: '#', target: '_blank' },
      // ],
      dropdown_items: [
        // { icon: 'mdi-ssh', text: 'Profile', href: '/profile' },
        // { divider: true },
        { text: 'API keys', href: '/?tab=API%20Keys' },
        { text: 'Billing', href: '/?tab=Billing' },
        { text: 'Logout', href: '/logout' }
      ],
      cliHelperModal: false,
    }
  },

  computed: {
    avatarUrl() {
      if (!this.$store.state.user.user) {
        return ''
      }
      if (this.$store.state.user.user.providerName === 'github') {
        return `https://github.com/${this.$store.state.user.user.name}.png?size=36`
      }
      if (this.$store.state.user.user.providerName === 'google') {
        return this.$store.state.user.user?.info?.avatar_url
      }
      return ''
    },
  },

  mounted() {
    this.$store.dispatch('GetUser')
  },

  methods: {
  }
}
</script>
