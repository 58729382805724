import axios from 'axios'

const billing = {
  state: () => ({
    loading: false,
    billingPlans: [],
    billingAccount: null,
    error: null,
  }),

  getters: {
    getBillingPlan: (state) => (billingPlanId) => {
      if (state.billingPlans.length > 0) {
        const found = state.billingPlans.find(billingPlan => {
          if (billingPlan.id == billingPlanId) {
            return billingPlan
          }
        })
        if (found) {
          return found
        }
        return {
          name: 'Unknown',
        }
      }
      return {
        name: 'Unknown',
      }
    }
  },

  mutations: {
    SET_BILLING_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_BILLING_ERROR: (state, error) => {
      state.error = error
    },
    SET_BILLING_PLANS: (state, billingPlans) => {
      state.billingPlans = billingPlans
    },
    SET_BILLING_ACCOUNT: (state, billingAccount) => {
      state.billingAccount = billingAccount
    }    
  },
  actions: {    
    GetBillingAccount (context, query) {
      context.commit('SET_BILLING_LOADING', true)
      context.commit('SET_BILLING_ERROR', null)
      return axios.get(`/projects/${query.projectId}/billing/account`)
        .then((response) => {
          context.commit('SET_BILLING_LOADING', false)
          context.commit('SET_BILLING_ACCOUNT', response.data)
        })
        .catch((error) => {
          context.commit('SET_BILLING_LOADING', false)
          context.commit('SET_BILLING_ERROR', error.response.data.error.message)
        })
    },    
    ChangePlan(context, query) {
      context.commit('SET_BILLING_LOADING', true)
      context.commit('SET_BILLING_ERROR', null)
      return axios.post(`/projects/${query.projectId}/billing/change-plan`, {
        planId: query.planId,
      })
        .then((response) => {
          context.commit('SET_BILLING_LOADING', false)
          if (response.data.redirectRequired) {
            window.location.replace(response.data.redirectUrl);
            return
          }
          // All good
        })
        .catch((error) => {
          context.commit('SET_BILLING_ERROR', error.response.data.error.message)
          context.commit('SET_BILLING_LOADING', false)
        })
    },
    AddPaymentMethod(context, query) {
      context.commit('SET_BILLING_ERROR', null)
      return axios.post(`/projects/${query.projectId}/billing/payment-method`)
        .then((response) => {
          window.location.replace(response.data.redirectUrl);
        })
        .catch((error) => context.commit('SET_BILLING_ERROR', error.response.data.error.message))
    },
    RemovePaymentMethod(context, query) {
      context.commit('SET_BILLING_ERROR', null)
      return axios.delete(`/projects/${query.projectId}/billing/payment-method`)
        .then((response) => {
          context.commit('SET_BILLING_ACCOUNT', response.data)
        })
        .catch((error) => context.commit('SET_BILLING_ERROR', error.response.data.error.message))
    },
    // ListBillingPlans are the plans that are available to the user to subscribe.
    ListBillingPlans (context) {
      context.commit('SET_BILLING_LOADING', true)
      return axios.get(`/billing/plans`)
        .then((response) => {
          context.commit('SET_BILLING_PLANS', response.data)
          context.commit('SET_BILLING_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_BILLING_ERROR', error.response.data.error.message)
          context.commit('SET_BILLING_LOADING', false)
        })
    }
  }
}

export default billing
