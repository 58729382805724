import axios from 'axios'

const model = {
  state: () => ({
    loading: false,
    models: [],
    model: null,
    created: {},
    error: null,

    checkServerLoading: false,
    checkServer: null
  }),

  mutations: {
    SET_MODEL_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_MODEL_ERROR: (state, error) => {
      state.error = error
    },
    SET_MODELS: (state, models) => {
      state.models = models
    },
    SET_MODEL: (state, model) => {
      state.model = model
    },
    SET_CREATED_MODEL: (state, created) => {
      state.created = created
    },
    SET_MODEL_CHECK_LOADING: (state, checkServerLoading) => {
      state.checkServerLoading = checkServerLoading
    },
    SET_MODEL_CHECK_SERVER: (state, checkServer) => {
      state.checkServer = checkServer
    }
  },

  actions: {
    ListModels (context, query) {
      return axios.get(`/projects/${query.projectId}/models`)
        .then((response) => {
          context.commit('SET_MODELS', response.data)
          context.commit('SET_MODEL_LOADING', false)
        })
        .catch((error) => context.commit('SET_MODEL_ERROR', error.response.data.error.message))
    },
    GetModel (context, query) {
      if (!query.background) {
        context.commit('SET_MODEL_LOADING', true)
      }
      return axios.get(`/projects/${query.projectId}/models/${query.modelId}`)
        .then((response) => {
          context.commit('SET_MODEL', response.data)
          context.commit('SET_MODEL_LOADING', false)
        })
        .catch((error) => context.commit('SET_MODEL_ERROR', error.response.data.error.message))
    },
    // SetModel is used for both updating and creating a model
    SetModel (context, model) {
      context.commit('SET_MODEL_ERROR', null)
      context.commit('SET_MODEL_LOADING', true)
      return axios.post(`/projects/${model.projectId}/models`, model)
        .then((response) => {
          context.commit('SET_CREATED_MODEL', response.data)
          context.commit('SET_MODEL_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_MODEL_LOADING', false)
          context.commit('SET_MODEL_ERROR', error.response.data.message)
        })
    },
    CheckModelServer (context, request) {
      context.commit('SET_MODEL_CHECK_LOADING', true)
      context.commit('SET_MODEL_CHECK_SERVER', null)
      return axios.post(`/projects/${request.projectId}/models-check-server`, request)
        .then((response) => {
          context.commit('SET_MODEL_CHECK_SERVER', JSON.stringify(response.data))
          context.commit('SET_MODEL_CHECK_LOADING', false)
        })
        .catch((error) => context.commit('SET_MODEL_ERROR', error.response.data.error.message))
    },
    DeleteModel (context, model) {
      context.commit('SET_MODEL_LOADING', true)
      return axios.delete(`/projects/${model.projectId}/models/${model.id}`)
        .then(() => {})
        .catch((error) => context.commit('SET_MODEL_ERROR', error.response.data.error.message))
    }
  }
}

export default model
