import axios from 'axios'
import Vue from 'vue'

const imageGens = {
  state: () => ({
    loading: false,
    gens: [],
    textCompletions: [],
    pagination: {
      pageSize: 10,
      totalItems: 0,
      nextPageToken: '',
      previousPageToken: '',
      currentPageToken: ''
    },
    stats: {},
    textStats: {},
    error: null,
    generated: {}
  }),

  mutations: {
    SET_IMAGE_GENS_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_IMAGE_GENS_ERROR: (state, error) => {
      state.error = error
    },
    SET_IMAGE_GENS_PAGINATION_PAGE_SIZE: (state, pageSize) => {
      state.pagination.pageSize = pageSize
      // Also resetting the pagination token
      // as it's not valid for certain situations
      state.pagination.nextPageToken = ''
      state.pagination.previousPageToken = ''
    },
    SET_IMAGE_GENS_PAGINATION_CURRENT_PAGE_TOKEN: (state, currentPageToken) => {
      state.pagination.currentPageToken = currentPageToken
    },
    SET_IMAGE_GENS_PAGINATION: (state, headers) => {
      if ('previous-page-token' in headers) {
        state.pagination.previousPageToken = headers['previous-page-token']
      } else {
        state.pagination.previousPageToken = ''
      }

      if ('next-page-token' in headers) {
        state.pagination.nextPageToken = headers['next-page-token']
      } else {
        state.pagination.nextPageToken = ''
      }
      state.pagination.totalItems = parseInt(headers['total-items'])
    },
    SET_IMAGE_GENS: (state, gens) => {
      state.gens = gens
    },
    SET_TEXT_COMPLETIONS: (state, textCompletions) => {
      state.textCompletions = textCompletions
    },
    SET_IMAGE_GEN_STATS: (state, stats) => {
      state.stats = stats
    },
    SET_TEXT_GEN_STATS: (state, textStats) => {
      state.textStats = textStats
    },
    SET_IMAGE_GENERATED: (state, generated) => {
      state.generated = generated
    },
  },

  actions: {
    SetImageGensPageSize(context, pageSize) {
      Vue.ls.set('IMAGE_GENS_PAGE_SIZE', pageSize)
      context.commit('SET_IMAGE_GENS_PAGINATION_PAGE_SIZE', pageSize)
    },
    LoadImageGensPageSize(context) {
      let pageSize = Vue.ls.get('IMAGE_GENS_PAGE_SIZE');
      context.commit('SET_IMAGE_GENS_PAGINATION_PAGE_SIZE', pageSize)
    },
    ListImageGens (context, query) {
      context.commit('SET_IMAGE_GENS_LOADING', true)

      let path = `/gens?project=${query.projectId}`

      if (query.user) {
        path += `&user=${query.user}`
      }

      if (query.status) {
        path += `&status=${query.status}`
      }

      if (query.model) {
        path += `&model=${query.model}`
      }

      if (query.pageSize) {
        path += `&pageSize=${query.pageSize}`
      }

      if (query.pageToken !== '') {
        path = path + `&pageToken=${query.pageToken}`
      }

      // return axios.get(`/gens?project=${query.projectId}&user=${query.user}&status=${query.status}`)
      return axios.get(path)
        .then(async (response) => {
          // lookup the model name for each image gen
          // and set it on the object
          let genRequests = await Promise.all(
            response.data.results.map((gen) => {
              return axios.get(`/chat/completions/${gen.modelId}/payload?b64url=${btoa(gen.requestBodyPath)}`);
            })
          )

          response.data.results = await Promise.all(
            genRequests.map(async (genRequest) => {
              return {
                ...response.data.results[genRequests.indexOf(genRequest)],
                requestBody: JSON.stringify(genRequest.data, null, 2),
              };
            })
          );

          context.commit("SET_IMAGE_GENS_PAGINATION_CURRENT_PAGE_TOKEN", query.pageToken)
          context.commit("SET_IMAGE_GENS_PAGINATION", response.headers)

          context.commit('SET_IMAGE_GENS', response.data.results)
          context.commit('SET_IMAGE_GENS_LOADING', false)
        })
        .catch((error) => {
          console.log(error)
          // context.commit('SET_IMAGE_GENS_ERROR', error.response.data.error.message)
          context.commit('SET_IMAGE_GENS_ERROR', error)
        })
    },
    ListTextCompletions (context, query) {
      context.commit('SET_IMAGE_GENS_LOADING', true)

      let path = `/chat/completions?project=${query.projectId}`

      if (query.user) {
        path += `&user=${query.user}`
      }

      if (query.status) {
        path += `&status=${query.status}`
      }

      if (query.model) {
        path += `&model=${query.model}`
      }

      if (query.pageSize) {
        path += `&pageSize=${query.pageSize}`
      }

      if (query.pageToken !== '') {
        path = path + `&pageToken=${query.pageToken}`
      }
      
      return axios.get(path)
        .then(async (response) => {
          // lookup the model name for each image gen
          // and set it on the object
          for (const item of response.data.results) {
            try {
              // Query /chat/completions/<model-id>/payload?b64url=<item.requestBodyPath> API
              // to get the request prompts for each item
              const payloadResponse = await axios.get(`/chat/completions/${item.modelId}/payload?b64url=${btoa(item.requestBodyPath)}`);
              // Stringify the JSON response
              item.requestBody = JSON.stringify(payloadResponse.data, null, 2);
            } catch (error) {
              console.log(error);
            }
          }

          context.commit("SET_IMAGE_GENS_PAGINATION_CURRENT_PAGE_TOKEN", query.pageToken);
          context.commit("SET_IMAGE_GENS_PAGINATION", response.headers);

          context.commit('SET_TEXT_COMPLETIONS', response.data.results);
          context.commit('SET_IMAGE_GENS_LOADING', false);
        })
        .catch((error) => {
          console.log(error)
          context.commit('SET_IMAGE_GENS_ERROR', error.response.data.error.message)
        })
    },
    DeleteGen (context, gen) {
      context.commit('SET_IMAGE_GENS_LOADING', true)
      return axios.delete(`/gens/${gen.id}?project=${gen.projectId}`)
        .then(() => {})
        .catch((error) => context.commit('SET_IMAGE_GENS_ERROR', error.response.data.error.message))
    },
    GetImageGenStats (context, query) {      
      context.commit('SET_IMAGE_GENS_LOADING', true)
      return axios.get(`/projects/${query.projectId}/status/gens?type=image&model=${query.model}`)
        .then((response) => {
          context.commit('SET_IMAGE_GEN_STATS', response.data)
          context.commit('SET_IMAGE_GENS_LOADING', false)
        })
        .catch((error) => {
          context.commit('SET_IMAGE_GENS_LOADING', false)
          context.commit('SET_IMAGE_GENS_ERROR', error.response.data.error.message)
        })
    },
    GetTextGenStats (context, query) {
      return axios.get(`/projects/${query.projectId}/status/gens?type=text&model=${query.model}`)
        .then((response) => {
          context.commit('SET_TEXT_GEN_STATS', response.data)
        })
        .catch((error) => {          
          context.commit('SET_IMAGE_GENS_ERROR', error.response.data.error.message)
        })
    },
    GenerateImage (context, payload) {
      context.commit('SET_IMAGE_GENS_LOADING', true)

      const config = {
        headers:{
          'X-Cluster': payload.cluster,
          'X-User': payload.user,
          'X-Async': payload.async.toString(),
        }
      }

      return axios.post(`/images/generations`, payload, config)
        .then((response) => {
          context.commit('SET_IMAGE_GENS_LOADING', false)
          context.commit('SET_IMAGE_GENERATED', response.data)

        })
        .catch((error) => {
          context.commit('SET_IMAGE_GENS_LOADING', false)
          context.commit('SET_IMAGE_GENS_ERROR', error.response.data.error.message)
        })
    }
  }
}

export default imageGens
