import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/store/modules/user'
import billing from '@/store/modules/billing'
import notification from '@/store/modules/notification'
import auth from '@/store/modules/auth'
import model from '@/store/modules/model'
import imageGens from '@/store/modules/imageGens'
import endUser from '@/store/modules/endUser'
import endUserTransactions from '@/store/modules/endUserTransactions'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {    
    user,
    billing,
    notification,
    auth,
    model,
    imageGens,
    endUser,
    endUserTransactions
  },

  getters: {
    jwt() {
      return localStorage.getItem("auth_token_default") || ''
    },
  },

  strict: debug
})
