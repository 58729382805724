import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
  return () => import(`@/components/${view}.vue`);
}

Vue.router = new VueRouter({
  hashbang: false,
  mode: 'history',
  base: __dirname,
  beforeRouteUpdate (to, next) {
    if (to.matched.some(record => record.meta.auth)) {
      if (!this.$auth.check()) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  },
  afterEach (to) {
    document.title = to.meta.title || 'Meteron'
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: loadView('home/HomePage'),
      meta: {
        auth: true,
        title: 'Dashboard'
      }
    },
    {
      path: '/models/:modelId',
      name: 'modelDetails',
      component: loadView('home/ModelDetailsPage'),
      meta: {
        auth: true,
        title: 'Edit model endpoint'
      }
    },
    {
      path: '/models/:modelId/logs',
      name: 'modelLogs',
      component: loadView('logs/LogsPage'),
      meta: {
        auth: true,
        title: 'Model Logs'
      }
    },
    // {
    //   path: '/models/:modelId/transactions',
    //   name: 'transactions',
    //   component: loadView('endUsers/EndUserTransactionsPage'),
    //   meta: {
    //     auth: true,
    //     title: 'Transactions'
    //   }
    // },
    {
      path: '/users',
      name: 'users',
      component: loadView('endUsers/EndUsersPage'),
      meta: {
        auth: true,
        title: 'Your end users'
      }
    },
    {
      path: '/users/:userId',
      name: 'userDetails',
      component: loadView('endUsers/EndUserDetailsPage'),
      meta: {
        auth: true,
        title: 'End user details page'
      }
    },
    {
      path: '/plans',
      name: 'plans',
      component: loadView('billing/PlansPage'),
      meta: {
        auth: true,
        title: 'Plans'
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: loadView('profile/ProfilePage'),
      meta: {
        auth: true,
        title: 'Profile'
      }
    },   
    {
      path: '/login',
      name: 'login',
      component: loadView('auth/LoginPage'),
      meta: {
        auth: false,
        title: 'Login'
      }
    },
    {
      path: '/oauth/:provider',
      name: 'oauthCallback',
      component: loadView('auth/OAuthCallbackPage'),
      meta: {
        auth: false,
        title: 'OAuth'
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: loadView('auth/LogoutPage'),
      meta: {
        auth: true,
        title: 'Logout'
      }
    }
  ]
})

export default Vue.router
