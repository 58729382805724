import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

const opts = {
  theme: {
    dark: true,
    themes: {
      light: {
        primary: colors.green.darken1, 
        // primary: colors.deepPurple.darken4, 
        secondary: colors.grey.darken2,
        info: colors.blue.accent2,
        warning: colors.amber.darken2,
        error: colors.red.accent3,
        success: colors.green.accent4
      },
      dark: {        
        // primary: "#6366f1",
        primary: "#0ea5e9",
        secondary: colors.grey.lighten1,
        info: colors.cyan.lighten4,
        warning: colors.amber.darken2,
        error: colors.red.accent4,
        success: colors.purple.accent2,
        background: '#000000',
      }
    }
  }
}

export default new Vuetify(opts);
