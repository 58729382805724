import axios from 'axios'
import Vue from 'vue'

const endUser = {
  state: () => ({
    loading: false,
    users: [],
    pagination: {
      pageSize: 10,
      totalItems: 0,
      nextPageToken: '',
      previousPageToken: '',
      currentPageToken: ''
    },
    error: null    
  }),

  mutations: {
    SET_END_USERS_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_END_USERS_ERROR: (state, error) => {
      state.error = error
    },
    SET_END_USERS_PAGINATION_PAGE_SIZE: (state, pageSize) => {
      state.pagination.pageSize = pageSize
      // Also resetting the pagination token
      // as it's not valid for certain situations
      state.pagination.nextPageToken = ''
      state.pagination.previousPageToken = ''
    },
    SET_END_USERS_PAGINATION_CURRENT_PAGE_TOKEN: (state, currentPageToken) => {
      state.pagination.currentPageToken = currentPageToken
    },
    SET_END_USERS_PAGINATION: (state, headers) => {
      if ('previous-page-token' in headers) {
        state.pagination.previousPageToken = headers['previous-page-token']
      } else {
        state.pagination.previousPageToken = ''
      }

      if ('next-page-token' in headers) {
        state.pagination.nextPageToken = headers['next-page-token']
      } else {
        state.pagination.nextPageToken = ''
      }
      state.pagination.totalItems = parseInt(headers['total-items'])
    },
    SET_END_USERS: (state, users) => {
      state.users = users
    },    
  },

  actions: {
    SetEndUsersPageSize(context, pageSize) {
      Vue.ls.set('END_USERS_PAGE_SIZE', pageSize)
      context.commit('SET_END_USERS_PAGINATION_PAGE_SIZE', pageSize)
    },
    LoadImageGensPageSize(context) {
      let pageSize = Vue.ls.get('END_USERS_PAGE_SIZE');
      context.commit('SET_END_USERS_PAGINATION_PAGE_SIZE', pageSize)
    },
    ListEndUsers (context, query) {
      context.commit('SET_END_USERS_LOADING', true)

      let path = `/projects/${query.projectId}/end-users`

      if (query.pageSize) {
        path += `?pageSize=${query.pageSize}`
      }

      if (query.pageToken !== '') {
        path = path + `&pageToken=${query.pageToken}`
      }

      // return axios.get(`/gens?project=${query.projectId}&user=${query.user}&status=${query.status}`)
      return axios.get(path)
        .then(async (response) => {
          context.commit("SET_END_USERS_PAGINATION_CURRENT_PAGE_TOKEN", query.pageToken)
          context.commit("SET_END_USERS_PAGINATION", response.headers)

          context.commit('SET_END_USERS', response.data.results)
          context.commit('SET_END_USERS_LOADING', false)
        })
        .catch((error) => {
          console.log(error)     
          context.commit('SET_END_USERS_LOADING', false)     
          context.commit('SET_END_USERS_ERROR', error)
        })
    },      
  }
}

export default endUser
