import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', function (value) {
  return moment(value).format('YYYY-MM-DD h:mm:ss a')
})

Vue.filter('ymd', function (value) {
  return moment(value).format('MMMM Do YYYY')
})

Vue.filter('ago', function (value) {
  return moment(value).fromNow();
})

Vue.filter('abs', function (value) {
  return Math.abs(value);
})

Vue.filter('b64decode', function (value) {
  return atob(value);
})

Vue.filter('size', function (bytes) {
  const si=true
  const dp=1

  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10**dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)


  return bytes.toFixed(dp) + ' ' + units[u]
})
