<template>
  <div>
    <div v-if="$auth.ready()">
      <!-- configuring sidebar only if user is authenticated -->
      <div v-if="$auth.check()">
        <!-- <v-app dark :style="{background: $vuetify.theme.themes[theme].background}"> -->
        <v-app dark >
          <!-- <navigation-drawer></navigation-drawer> -->
          <toolbar></toolbar>
          <v-main>
            <!-- <v-container container--fluid class="mb-14"> -->
            <v-container class="mb-14">
              <router-view></router-view>
              <notification></notification>
            </v-container>
          </v-main>     
          <v-footer padless>
            <v-col
              class="text-center caption"
              cols="12"              
            >
              <span>
                Got a question or encountered a problem? Contact <a href="mailto:hey@meteron.ai?subject=Question about Meteron">hey@meteron.ai</a>
              </span>
            </v-col>
          </v-footer>
        </v-app>
      </div>
      <!-- for unauthenticated users - do not add fixed sidebar -->
      <div v-else>
        <v-app>
          <router-view></router-view>
        </v-app>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from './components/Toolbar';
import Notification from './components/Notification';

export default {
  name: 'App',

  components: {
    Toolbar,
    Notification,    
  },

  data: () => ({

  }),

  mounted() {
    this.$auth
      .load()
      .then(() => {
        if (this.$auth.check()) {
          window.$crisp.push(['set', 'user:email', this.$store.state.user.user?.email])
          window.$crisp.push(['set', 'user:nickname', this.$store.state.user.user?.name])
        }
      })
  },

  watch: {
    '$route'(to) {
      document.title = to.meta.title || 'Meteron'
    }
  },

  computed: {
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },

  methods: {
    
  }
};
</script>

<style>

.shiny-button {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --border-opacity: 1;
  --bg-opacity: 1;
  --space-y-reverse: 0;
  box-sizing: border-box;
  border: 0 solid #e2e8f0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border-radius: .25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-weight: 600;
  /* padding: .5rem; */
  --text-opacity: 1;
  color: rgba(255,255,255,var(--text-opacity));
  /* width: 100%; */
  content: "";
  background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
  animation: anime 3s ease infinite alternate;
  -webkit-animation: anime 3s ease infinite alternate;
  background-size: 300% 300%;
}

@-webkit-keyframes anime {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}
@keyframes anime {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}

</style>